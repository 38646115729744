export default {
  tableFields: [
    {
      name: '__component:badge-column',
      title: '',
      dataClass: 'text-center',
      width: '4%'
    },
    {
      name: 'name',
      title: 'Nombre',
      sortField: 'name',
      width: '20%'
    },
    {
      name: 'mac',
      title: 'Mac',
      sortField: 'mac',
      width: '20%'
    },
    {
      name: 'serial',
      title: 'Serial',
      sortField: 'serial',
      width: '20%'
    },
    {
      name: 'model',
      title: 'Modelo',
      sortField: 'model',
      width: '10%'
    },
    {
      name: 'softwareversion',
      title: 'Version de SoftWare',
      sortField: 'softwareversionl',
        width: '10%'
    },
    {
      name: '__slot:active',
      title: 'Actions',
      titleClass: 'center aligned',
      dataClass: 'center aligned',
      width: '25%',
    },
  ],
  sortFunctions: {
    'name': function (item1, item2) {
      return item1 >= item2 ? 1 : -1
    },
  }
}
